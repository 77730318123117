@import "../../../styles/styles.scss";

.service-card {
  font-family: "Manrope", sans-serif;
  display: flex;
  flex-direction: column;
  border-radius: 35px;
  width: fit-content;
  width: 530px;
  height: 350px;

  .service-container {
    position: relative;
    .background-image {
      width: 100%;
      height: 100%;
      border-radius: 35px;
    }
  }

  .service-info {
    position: absolute;
    transform: translateY(-50%);
    background-color: white;
    border: 2px solid $dark-green-border;
    text-align: left;
    border-radius: 32px;
    padding: 30px;
    position: absolute;
    .service-info-logo {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: $tea-green;
      border-radius: 50%;
      right: 20px;
      top: 0;
      transform: translateY(-50%);
      img {
        width: auto;
        height: auto;
      }
    }
    h2 {
      font-size: 1.2rem;
      margin-block-start: 0;
      color: $cambridge-green;
      margin-bottom: 10px;
      color: $cambridge-green;
    }
    p {
      color: $neutral-font-color;
      width: 450px;
      line-height: 1.5rem;
      font-size: 0.95rem;
      margin-block-end: 0;
      font-weight: 500;
    }
  }
}

@include respond-to(lg) {
  .service-card {
    width: auto;
    height: auto;
    max-width: 480px;
    margin: 0 auto;
    .service-container {
      .background-image {
        width: 100%;
        height: auto;
        border-radius: 15px;
      }
    }
    .service-info {
      position: static;
      transform: translateY(0);
      display: block;
      border: 0;
      padding: 20px;
      p {
        margin-block-start: 0;
        width: auto;
        margin-bottom: 20px;
      }
    }
  }
}
