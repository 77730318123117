@import "../../styles/styles.scss";

.why-us-section {
  margin-top: 100px;
  margin-bottom: 50px;
  font-family: "Manrope", sans-serif;
  .why-us-content {
    max-width: $max-screen-width;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    .why-us-image {
      width: 60%;
      display: flex;
      justify-content: center;
      img{
        width: 70%;
        margin: 0 auto;
      }
    }
    .why-us-text {
      justify-content: center;
      width: 40%;
      text-align: left;
      display: flex;
      flex-direction: column;
      h3 {
        margin-block-start: 0;
        margin-block-end: 0;
        font-weight: 400;
        font-size: 1.2rem;
        color: $cambridge-green;
      }
      h2 {
        font-size: 4rem;
        margin-block-start: 10px;
        margin-block-end: 15px;
        color: $neutral-font-color;
        font-weight: 700;
        line-height: 4rem;
      }
      p {
        color: $light-font-color;
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 1.5rem;
      }
      .why-us-values {
        margin-top: 25px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        .why-us-value {
          display: flex;
          flex-direction: column;
          text-align: center;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 68px;
            height: 68px;
            margin: 0 auto;
            border-radius: 50%;
            margin-bottom: 15px;
            background-color: $tea-green;
          }
        }
      }
    }
  }
}

@include respond-to(lg) {
  .why-us-section {
    margin-top: 20px;
    .why-us-content {
      flex-direction: column;
      .why-us-image {
        width: 90%;
        max-width: 440px;
        height: auto;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
      .why-us-text {
        width: auto;
        margin: 30px;
        .why-us-values {
          flex-direction: column;
          .why-us-value {
            margin-bottom: 30px;
            flex-direction: row;
            align-items: center;
            gap: 30px;
            margin-left: 50px;
            font-weight: bold;
            span {
              margin: 0;
            }
          }
        }
        h3 {
          text-align: center;
        }
        h2 {
          font-size: 3rem;
          line-height: 3rem;
          text-align: center;
        }
        p {
          text-align: center;
        }
      }
    }
  }
}
