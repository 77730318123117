// Colores
$color-primary: #0056b3;
$color-secondary: #0063cc;
$color-accent: #ff4081;
$background-color: #f8f9fa;
$tea-green: #E9F0D4;
$soft-green: #fbfcf7;

$neutral-font-color: #3d3d3d;
$light-font-color: #a0a0a0;
$soft-font-color: #4f4f4f;

$strong-green-button: #1d352e;

$dark-green-title: #60716c;
$cambridge-green: #9bac8a;
$dark-green-border: #d0d5d3;
$soft-green-background: #f4f7e9;

// Márgenes
$margin-standard: 20px;

// Media Queries
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1200px;
$max-screen-width: 1367px;


$h1-title-size: 3.5rem;
$h2-title-size: 2.5rem;
$input-text-size: 1rem;

@mixin respond-to($breakpoint) {
  @if $breakpoint == sm {
    @media (max-width: $breakpoint-sm) {
      @content;
    }
  } @else if $breakpoint == md {
    @media (max-width: $breakpoint-md) {
      @content;
    }
  } @else if $breakpoint == lg {
    @media (max-width: $breakpoint-lg) {
      @content;
    }
  } @else if $breakpoint == xl {
    @media (max-width: $breakpoint-xl) {
      @content;
    }
  }
}
