@import "../../../styles/styles.scss";

.login-container {
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  max-width: $max-screen-width;
  font-family: "Manrope", sans-serif;
  h1 {
    margin-top: 120px;
    font-size: $h1-title-size;
    margin-bottom: 30px;
  }
  .login-content {
    margin: 0 auto;
    max-width: 400px;
    background-color: $soft-green;
    padding: 70px 120px;
    border-radius: 70px;
    display: flex;
    flex-direction: column;
    .login-error {
      margin-block-start: 0;
      font-size: 0.8rem;
      color: red;
    }
    .or-with-separation {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;
      margin-top: 20px;
      margin-bottom: 40px;
      span {
        width: 35%;
        height: 2px;
        border-radius: 1px;
        background-color: $cambridge-green;
      }
      p {
        margin: 0;
      }
    }
    .login-button,
    .facebook-button,
    .google-button {
      color: white;
      font-size: 1.3rem;
      text-align: center;
      background-color: $cambridge-green;
      border: none;
      border-radius: 12px;
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      transition: 0.2s all;
      div {
        align-items: center;
        justify-content: center;
      }
      cursor: pointer;
      &:hover {
        background-color: $strong-green-button;
      }
    }
  }

  form {
    .login-question {
      margin: 0 auto;
      margin-top: 40px;
    }
    .login-form-group {
      margin-top: 35px;
      text-align: left;
      ::-webkit-input-placeholder {
        color: #d1d1d1;
      }
      label {
        display: block;
        margin-bottom: 8px;
      }
      input {
        border: 1px solid #e7e7e7;
        border-radius: 12px;
        width: 100%;
        padding: 18px;
        box-sizing: border-box;
        height: 40px;
        &::placeholder {
          font-size: 0.9rem;
        }
      }
    }
    .password-group {
      position: relative;
      .toggle-password-visibility {
        position: absolute;
        right: 8px;
        top: 0;
        bottom: 0;
        margin: auto;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1rem;
      }
    }
    .forgot-password {
      text-align: right;
      margin-bottom: 15px;
      a {
        text-decoration: none;
        color: #007bff;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .login-social-buttons {
    display: flex;
    flex-direction: column;

    gap: 15px;
    justify-content: space-around;
  }
  .signup-question {
    margin: 0 auto;
    margin-top: 40px;
    a {
      text-decoration: none;
      color: #007bff;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@include respond-to(lg) {
  .login-container {
    h1 {
      margin-top: 20px;
    }
    .login-content {
      padding: 70px;
    }
  }
}
