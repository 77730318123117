@import "../../styles/styles.scss";

.welcome-container {
  background-image: url("../../assets/images/herobackground.jpg");
  background-size: cover;
  width: 100%;
  height: 80vh;
  min-height: 700px;
  background-position: center;
  font-family: "Manrope", sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  .welcome-booking {
    width: 70%;
    max-width: $max-screen-width;
    margin: 0 auto;
    height: 30%;
  }
  .welcome-section {
    display: flex;
    margin: 0 auto;
    padding-left: 100px;
    width: 100%;
    height: 70%;
    max-width: $max-screen-width;
    text-align: left;
    .welcome-content {
      margin-top: 80px;
      width: 470px;
      height: 390px;
      h1 {
        font-weight: 700;
        color: $neutral-font-color;
        line-height: 54px;
        font-size: 4rem;
      }
      p {
        margin-top: 20px;
        font-weight: 500;
        color: $soft-font-color;
        line-height: 24px;
        font-size: 1.2rem;
        span {
          font-weight: 700;
          color: $cambridge-green;
        }
      }
      button {
        margin-top: 20px;
        padding: 20px 36px;
        background-color: $cambridge-green;
        color: white;
        border-radius: 12px;
        border: none;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 28px;
        cursor: pointer;
        transition: 0.2s all;
        &:hover {
          background-color: $strong-green-button;
        }
      }
    }
  }
}

@include respond-to(lg) {
  .welcome-container {
    background-image: url("../../assets/images/backgroundmobile.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: inherit;
    height: auto;
    .welcome-section {
      padding-left: 0;
      margin: 30px;
      width: auto;
      text-align: center;
      .welcome-content {
        margin-top: 80px;
        width: auto;
        height: auto;
        h1 {
          opacity: 0;
          -webkit-text-stroke: 0.01px white;
          font-size: 3rem;
        }
      }
    }
    .welcome-booking {
      width: 90%;
      margin: 0 auto;
      height: 30%;
    }
  }
}
