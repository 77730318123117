@import "../../styles/styles.scss";

.platform-section {
  font-family: "Manrope", sans-serif;
  margin-top: 100px;
  .platform-section-content {
    max-width: $max-screen-width;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .platform-steps {
      display: flex;
      flex-direction: row;
      width: 100%;
      .steps-image {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 80%;
        }
      }
      .platform-steps-list {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        text-align: left;
        z-index: 10;
        width: 50%;
        li {
          margin-bottom: 30px;
          z-index: 1;
        }
        li:first-child {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            left: 25px;
            top: 0;
            bottom: 0;
            width: 2px;
            z-index: -1;
            height: 340px;
            background: repeating-linear-gradient(
              to bottom,
              $cambridge-green 0%,
              $cambridge-green 50%,
              transparent 50%,
              transparent 100%
            );
            background-size: 2px 10px;
          }
        }
        h3 {
          font-size: 2rem;
          color: $neutral-font-color;
          font-weight: 700;
          margin-block-end: 0;
          margin-block-start: 0;
          span {
            z-index: 100;
            margin-right: 20px;
            display: inline-block;
            text-align: center;
            font-size: 2.2rem;
            background-color: $cambridge-green;
            color: white;
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
        }
        p {
          margin-left: 75px;
        }
      }
    }
    .platform-text {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      text-align: center;
      width: 780px;
      h2 {
        font-size: 3rem;
        margin-block-start: 0;
        margin-block-end: 15px;
        color: $neutral-font-color;
        font-weight: 600;
        span {
          color: $cambridge-green;
        }
      }
      p {
        font-size: 1.1rem;
        font-weight: 500;
        color: $light-font-color;
        margin-bottom: 50px;
      }
    }
  }
}

@include respond-to(lg) {
  .platform-section {
    margin-top: 50px;
    .platform-section-content {
      width: auto;
      margin: 30px;
      .platform-text {
        width: auto;
        h2 {
          font-size: 2rem;
          margin-block-end: 5px;
        }
        p{
          margin-bottom: 20px;
        }
      }
      .platform-steps {
        width: auto;
        flex-direction: column;
        .steps-image {
          display: none;
        }
        .platform-steps-list {
          width: auto;
          padding-inline-start: 0;
          h3 {
            display: flex;
            flex-direction: row;
            gap: 10px;
            font-size: 1.5rem;
            span {
              margin-right: 5px;
              font-size: 1.5rem;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
            }
          }
          p {
            margin-left: 60px;
          }
          li:first-child {
            position: relative;
            &::before {
              left: 20px;
              height: 400px;
            }
          }
        }
      }
    }
  }
}
