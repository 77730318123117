@import "../../../styles/styles.scss";

.toast {
  position: fixed;
  width: 100%;
  bottom: -100px;
  font-family: "Manrope", sans-serif;
  padding: 20px 20px;
  background-color: $cambridge-green;
  color: white;
  font-size: 1.1rem;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: bottom 0.5s ease-in-out;
  z-index: 1000;
}

.toast.show {
  bottom: 0;
}
