@import "../../styles/styles.scss";

.reach-support-section {
  text-align: center;
  font-family: "Manrope", sans-serif;
  color: $neutral-font-color;
  margin-bottom: 100px;
  .reach-support-section-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $max-screen-width;
    margin: 0 auto;
    .reach-support-content {
      position: relative;
      .label-content {
        display: none;
      }
      .hidden-logo {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 8px;
        margin: auto;
      }
    }
  }
  h2 {
    margin-top: 80px;
    font-size: $h2-title-size;
  }
  p {
    margin-block-start: 10px;
    margin-block-end: 10px;
    color: $light-font-color;
    width: 450px;
    font-size: 1.08rem;
  }

  form {
    margin: 20px 0;
    width: 900px;
    height: 110px;
    background-color: #f4f7e9;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    input {
      height: 52px;
      width: 230px;
      border: 1px solid $light-font-color;
      border-radius: 12px;
      gap: 10px;
      padding: 0 20px;
      padding-left: 44px;
    }
    ::-webkit-input-placeholder {
      font-weight: 500;
      font-size: 0.9rem;
      color: #b0b0b0;
    }
    button {
      background-color: $cambridge-green;
      height: 52px;
      width: 180px;
      border: none;
      border-radius: 12px;
      font-weight: 500;
      font-size: 1.5rem;
      color: white;
      gap: 8px;
      cursor: pointer;
      transition: 0.2s all;
      &:hover {
        background-color: $strong-green-button;
      }
    }
  }
}

@include respond-to(lg) {
  .reach-support-section {
    margin-top: 20px;
    margin-bottom: 40px;
    width: auto;
    .reach-support-section-container {
      margin: 30px;
      width: auto;
      .reach-support-content {
        .hidden-logo {
          display: none;
        }
        .label-content {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 10px;
          label {
            margin-bottom: 13px;
          }
        }
      }
      h1 {
        font-size: $h1-title-size;
        margin-top: 20px;
      }
      p {
        width: auto;
        margin-block-start: 0;
      }
      form {
        width: auto;
        padding: 30px;
        height: auto;
        border-radius: 10px;
        flex-direction: column;
        gap: 20px;
        input{
          padding-left: 20px;
          width: 270px;
        }
      }
    }
  }
}
