@import "../../styles/styles.scss";

.about-us-section {
  margin-top: 200px;
  width: 100%;
  background-color: #f4f7e9;
  display: flex;
  flex-direction: column;
  font-family: "Manrope", sans-serif;
  text-align: center;
  h1 {
    margin-top: 110px;
    font-size: $h1-title-size;
    color: $neutral-font-color;
    span {
      color: $cambridge-green;
    }
  }
  .about-us-section-container {
    width: 100%;
    max-width: $max-screen-width;
    margin: 0 auto;
  }
  .about-us-section-content {
    margin: 30px 130px;
    margin-bottom: 90px;
    display: flex;
    flex-direction: row;
    .about-us-section-image {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 430px;
      img {
        width: 100%;
        height: auto;
        border-radius: 20px;
        border: 2px solid $dark-green-border;
      }
    }

    .about-us-section-content-text {
      display: flex;
      flex-direction: column;
      margin-left: 60px;
      justify-content: space-evenly;
      p {
        text-align: left;
        color: $neutral-font-color;
        font-size: 1.15rem;
        line-height: 1.8rem;
      }
      button {
        background-color: $cambridge-green;
        font-size: 1.4rem;
        font-weight: 500;
        color: white;
        border: none;
        border-radius: 12px;
        gap: 8px;
        height: 60px;
        width: 320px;
        transition: 0.2s all;
        cursor: pointer;
        &:hover {
          background-color: $strong-green-button;
        }
      }
    }
  }
}

@include respond-to(lg) {
  .about-us-section {
    margin-top: 20px;
    h1 {
      margin-block-end: 5px;
      margin: 40px 30px 0 30px;
    }
    .about-us-section-content {
      flex-direction: column;
      margin: 30px;
      img {
        width: 100%;
        margin: 0 auto;
      }
      .about-us-section-image {
        margin: 0 auto;
      }
      .about-us-section-content-text {
        margin-left: 0;
        margin: 20px;
        p {
          text-align: center;
        }
        button {
          width: auto;
        }
      }
    }
  }
}
