@import "../../styles/styles.scss";

.footer {
  .footer-container {
    text-align: left;
    padding: 30px;
    max-width: $max-screen-width;
    margin: 0 auto;
  }
  font-family: "Manrope", sans-serif;
  color: $neutral-font-color;
  font-size: 1rem;
  background-color: $soft-green-background;
  font-weight: 440;
  .cut-line {
    background-color: $cambridge-green;
    display: block;
    width: 95%;
    margin: 0 auto;
    margin-top: 40px;
    height: 2px;
  }
  .footer-content {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    .footer-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 282px;
      margin-left: 30px;
      height: auto;
    }
    .footer-sections {
      display: flex;
      justify-content: space-around;
      width: 100%;
      .footer-section {
        h4 {
          font-weight: 550;
          margin-bottom: 20px;
          color: $cambridge-green;
        }
        ul {
          list-style: none;
          padding: 0;

          li {
            margin-bottom: 15px;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .footer-socials {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    span {
      color: $light-font-color;
      font-size: 1rem;
    }
    img {
      margin-right: 10px;
      width: 24px; // Ajusta según el tamaño de los íconos
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  @include respond-to(lg) {
    .footer-container {
      .footer-content {
        flex-direction: column-reverse;
      }
      .footer-logo {
        margin-left: 0;
        width: auto;
        img {
          width: 200px;
          margin-left: 0;
        }
      }
      .footer-sections {
        flex-direction: column;
        text-align: center;
        gap: 10px;
        margin-bottom: 20px;
      }
      .footer-socials{
        .social-logos{
          width: 60%;
        }
        span{
          text-align: right;
        }
      }
    }
  }
}
