@import "../../styles/styles.scss";

.appointment-card {
  font-family: "Manrope", sans-serif;
  padding: 40px;
  box-shadow: 3.12px 9.37px 21.85px 0px #0000003d;
  border-radius: 28px;
  background: $soft-green;
  display: flex;
  flex-direction: column;
  transform: translateY(100px);
  h2 {
    text-align: left;
    font-size: 2rem;
    line-height: 40px;
    font-weight: 700;
    color: $dark-green-title;
    margin-block-start: 0;
    margin-block-end: 0;
  }
  .appointment-card-form {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 10px;
    .bookNow {
      justify-content: flex-end;
    }
    .input-content {
      display: flex;
      flex-direction: column;

      .label-content {
        display: flex;
        align-items: center;
        gap: 10px;
        label {
          text-align: left;
          font-size: 1rem;
          font-weight: 600;
          line-height: 24px;
          color: #6e6e6e;
        }
      }
      input {
        width: 220px;
      }
      input,
      button {
        margin-top: 1rem;
        border-radius: 12px;
        border: 1px solid #e7e7e78c;
        padding: 18px;
      }
      input,
      ::-webkit-input-placeholder {
        font-weight: 500;
        font-size: 1.05rem;
        color: #999999;
      }
    }

    button {
      background-color: $cambridge-green;
      color: white;
      border: none;
      border-radius: 12px;
      font-size: 1.1rem;
      font-weight: 700;
      transition: 0.2s all;
      cursor: pointer;
      &:hover {
        background-color: $strong-green-button;
      }
    }
  }
}

@include respond-to(lg) {
  .appointment-card {
    transform: translateY(0);
    h2 {
      text-align: center;
      font-size: 1.4rem;
      line-height: 1.5rem;
    }
    .appointment-card-form {
      flex-direction: column;
      .input-content {
        justify-content: center;
        align-items: center;
        &.mobile-hidden {
          display: none;
        }
        input {
          margin-bottom: 16px;
          width: 100%;
          max-width: 400px;
        }
        .label-content {
          margin-top: 10p;
          flex-direction: column;
        }
      }
      button {
        font-size: 0.9rem;
      }
    }
  }
}
