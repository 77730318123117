@import "../../styles/styles.scss";

.booking-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Manrope", sans-serif;
  z-index: 1000;
}

.booking-modal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  .close-option {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
  }
  h2 {
    font-size: $h2-title-size;
    color: $soft-font-color;
  }
  .booking-person {
    margin: 0 auto;
    width: 160px;
    height: 160px;
    img {
      border-radius: 50%;
      width: 100%;
      height: auto;
    }
  }
  .booking-modal-list {
    list-style: none;
    padding: 0;
    li {
      margin-bottom: 10px;
      font-size: 1rem;
      span {
        color: $dark-green-title;
        font-weight: bold;
      }
    }
  }
  .call-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    span {
      font-size: 0.6rem;
    }
    button {
      cursor: pointer;
      border-radius: 12px;
      border: none;
      margin: 0 auto;
      width: 140px;
      height: 40px;
      font-size: 1.2rem;
      background-color: $cambridge-green;
      color: white;
      &:hover {
        background-color: $dark-green-title;
      }
    }
  }
}
