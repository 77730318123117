@import "../../../styles/styles.scss";

.signup-container {
  max-width: $max-screen-width;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  font-family: "Manrope", sans-serif;

  h1 {
    margin-top: 120px;
    font-size: $h1-title-size;
    margin-bottom: 30px;
  }

  p {
    span {
      color: $cambridge-green;
      font-weight: 600;
    }
    font-weight: 500;
    margin-bottom: 60px;
  }
  .signup-content {
    max-width: 400px;
    margin: 0 auto;
    background-color: $soft-green;
    padding: 70px 120px;
    border-radius: 70px;
    display: flex;
    flex-direction: column;
    .or-with-separation {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;
      margin-top: 20px;
      margin-bottom: 40px;
      span {
        width: 35%;
        height: 2px;
        border-radius: 1px;
        background-color: $cambridge-green;
      }
      p {
        margin: 0;
      }
    }
    .login-question {
      margin: 0 auto;
      margin-top: 40px;
    }
  }
  .signup-form-group {
    margin-bottom: 35px;
    text-align: left;
    .signup-error {
      font-size: 0.8rem;
      color: red;
    }
    ::-webkit-input-placeholder {
      color: #d1d1d1;
    }
    label {
      display: block;
      margin-bottom: 8px;
    }
    input {
      border: 1px solid #e7e7e7;
      border-radius: 12px;
      width: 100%;
      padding: 18px;
      box-sizing: border-box;
      height: 40px;
      font-size: $input-text-size;
      &::placeholder {
        font-size: 0.9rem;
      }
    }
  }
  .signup-password-group {
    position: relative;
    .toggle-password-visibility {
      position: absolute;
      right: 8px;
      top: 0;
      bottom: 0;
      margin: auto;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 16px;
    }
  }
  .signup-button{
    div{
      justify-content: center;
      align-items: center;
    }
  }
  .signup-button,
  .facebook-button,
  .google-button {
    color: white;
    font-size: 1.3rem;
    text-align: center;
    background-color: $cambridge-green;
    border: none;
    border-radius: 12px;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    transition: 0.2s all;
    cursor: pointer;
    &:hover {
      background-color: $strong-green-button;
    }
  }
  .signup-social-buttons {
    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: space-around;
  }
}

@include respond-to(lg) {
  .signup-container {
    h1 {
      margin-top: 20px;
    }
    .signup-content {
      padding: 70px;
    }
  }
}
