@import "../../styles/styles.scss";

.header {
  background-color: $soft-green;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 1rem;

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 45px 95px;
    max-width: $max-screen-width;
    margin: 0 auto;
    .login-buttons {
      display: flex;
      gap: 10px;
    }
    .mobile-menu-button {
      display: none;
    }
    .user-dropdown {
      color: $neutral-font-color;
      cursor: pointer;
      .user-name {
        position: relative;
      }
      &:hover .dropdown-content {
        position: absolute;
        display: block;
      }
      .dropdown-content {
        top: 20px;
        right: 0;
        display: none;
        position: absolute;
        background-color: $soft-green;
        min-width: 160px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        z-index: 1;
        border-radius: 10px;
        padding: 20px;
        a {
          margin-bottom: 20px;
          color: $neutral-font-color;
          display: block;
          text-decoration: none;
          transition: 0.2s all;
          &:hover {
            color: $cambridge-green;
          }
        }
        .logout-button {
          cursor: pointer;
          border: none;
          background-color: transparent;
          margin: 0 auto;
          padding: 0;
          transition: 0.2s all;
          font-weight: bold;
          font-size: 1rem;
          color: $cambridge-green;
          &:hover {
            color: $tea-green;
          }
        }
      }
    }
  }
  .header-logo img {
    height: 60px;
    width: auto;
    cursor: pointer;
  }

  .header-navigation {
    margin: 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    a {
      text-align: center;
      text-decoration: none;
      color: $neutral-font-color;
      transition: 0.2s all;
      &:hover {
        color: $cambridge-green;
      }
    }
  }
  .join-us {
    background-color: white;
    border: 2px solid $strong-green-button;
    color: $strong-green-button;
  }
  .login {
    background-color: $strong-green-button;
    border: none;
    color: white;
  }
  .header-join-us {
    border-radius: 12px;
    padding: 16px 24px;
    box-shadow: -8px 8px 28px 0px #0000000f;
    cursor: pointer;
    font-weight: 700;
    font-size: 1.4rem;
    transition: 0.2s all;
    line-height: 24px;
    &:hover {
      background-color: $cambridge-green;
    }
  }

  .language-switcher {
    position: absolute;
    top: 0;
    left: 0;
  }
}

@include respond-to(lg) {
  .header {
    position: relative;
    z-index: 100;
    .header-container {
      padding: 10px;
      .login-buttons {
        display: none;
        button {
          padding: 8px;
          font-size: 1rem;
          font-weight: bold;
        }
      }
    }
    .header-logo img {
      height: 50px;
      width: auto;
      cursor: pointer;
    }
    .header-navigation {
      display: none;
    }
    .mobile-menu-button {
      width: 40px;
      cursor: pointer;
      height: 40px;
      font-size: 1.5rem;
      display: flex !important;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
    }
    .mobile-menu {
      position: absolute;
      bottom: -250px;
      display: flex;
      gap: 20px;
      flex-direction: column;
      right: 0;
      width: 100%;
      z-index: -1;
      background-color: $soft-green;
      a {
        margin: 0px 40px;
        text-decoration: none;
        color: $neutral-font-color;
      }
      a:first-child {
        margin-top: 30px;
      }

      a:last-child {
        margin-bottom: 30px;
      }
    }
  }
}
