@import "../../styles/styles.scss";

.nurse-view {
  font-family: "Manrope", sans-serif;
  width: 100%;
  margin: 80px 0;
  .nurse-loader {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
  }
  .nurse-view-content {
    width: 100%;
    max-width: $max-screen-width;
    margin: 0px auto;
    background-color: $soft-green;
    border-radius: 40px;
    padding-bottom: 40px;
    .nurse-pagination {
      display: flex;
      justify-content: center;
      margin-top: 15px;
      gap: 10px;
      button {
        cursor: pointer;
        background-color: $cambridge-green;
        color: white;
        border: none;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-weight: 600;
        text-align: center;
        &:hover {
          background-color: $dark-green-border;
        }
        &:disabled {
          background-color: $dark-green-border;
        }
      }
    }
  }
  h1 {
    font-size: $h1-title-size;
    color: $neutral-font-color;
    text-align: center;
  }
  table {
    width: 90%;
    margin: 0 auto;
    border-collapse: separate;
    border-spacing: 0 30px;
    margin-top: 20px;
    border: none;
    th,
    td {
      padding: 8px;
      text-align: center;
    }
    thead {
      height: 80px;
    }
    th {
      background-color: transparent;
      border: none;
      color: $soft-font-color;
      font-size: 0.9rem;
      text-align: center;
    }
    tbody {
      tr {
        background-color: white;
        height: 110px;
        transition: 0.2s all;
        .section-content {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 25px;
        }
        .patient-profile {
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 25px;
          .profile-text {
            p {
              margin-block-end: 0;
              margin-block-start: 0;
            }
            .profile-name {
              font-size: 1.2rem;
            }
            .profile-location {
              display: flex;
              justify-content: center;
              gap: 7px;
              font-size: 0.7rem;
            }
          }
          .profile-picture {
            width: 76px;
            height: 76px;
            img {
              width: 100%;
              height: auto;
              border-radius: 50%;
            }
          }
        }
      }
      tr td:first-child {
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
      }
      tr td:last-child {
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
      }
      tr:hover {
        background-color: $tea-green;
      }
    }

    .booking-status {
      color: $cambridge-green;
      text-transform: lowercase;
      font-weight: bold;
      &::first-letter {
        text-transform: capitalize;
      }
    }
    .booking-action-button {
      .grab-booking-button {
        cursor: pointer;
        border-radius: 12px;
        border: none;
        width: 120px;
        height: 30px;
        background-color: $cambridge-green;
        color: white;
        &:hover {
          background-color: $dark-green-title;
        }
      }
    }
    .options-button-content {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    .options-button {
      display: flex;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 120px;
      border: 1px solid $dark-green-border;
      font-family: "Manrope", sans-serif;
      background-color: $tea-green;
      border-radius: 12px;
      cursor: pointer;
      transition: background-color 0.3s;
      &:hover {
        background-color: $dark-green-title;
      }
      &.delete{
        display: none;
        background-color: $soft-font-color;
        color: white;
        &:hover{
          background-color: $neutral-font-color;
        }
      }
    }
  }
}

@include respond-to(lg) {
  .nurse-view {
    font-family: "Manrope", sans-serif;
    margin: 80px 0;
    width: auto;
    .nurse-view-content {
      width: auto;
      overflow-x: scroll;
    }
  }
}
