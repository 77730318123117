@import "../../styles/styles.scss";

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
  text-align: center;
}

.modal-actions {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
  button {
    cursor: pointer;
    transition: 0.2s all;
    &:hover{
      background-color: $dark-green-title;
    }
  }
  .cancel {
    background-color: white;
    width: 150px;
    border-radius: 12px;
    height: 40px;
    border: 1px solid $dark-green-border;
  }
  .yes {
    border: none;
    border-radius: 12px;
    width: 150px;
    height: 40px;
    background-color: $cambridge-green;
    color: white;
  }
}
